import { Command } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/command_pb';
import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import {
  Device,
  Device_CommissioningState,
  Device_Mode,
  Device_OTAState,
  ProductType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { t } from 'i18next';
import { idFromName } from '~/shared/models/id-utils';
import { PeripheralModel } from '~/shared/models/system/PeripheralModel';

export class DeviceModel {
  readonly name: string;

  readonly id: string;

  readonly availableMetricTypes: MetricType[];

  readonly commissioningState: Device_CommissioningState;

  readonly peripherals: PeripheralModel[];

  readonly maintenanceModeCommand: Command | undefined;

  readonly mode: Device_Mode;

  readonly title: string | undefined;

  readonly isLinked: boolean;

  readonly allowedProductTypesLinked: Map<ProductType, boolean>;

  readonly updatingFirmwareOTA: boolean;

  readonly allowMaintenanceMode: boolean;

  constructor({
    name,
    availableMetricTypes,
    commissioningState,
    peripherals,
    availableCommands,
    title,
    mode,
    otaState,
    allowedProductTypesLinked,
  }: Device) {
    this.name = name;
    this.id = idFromName(name);
    this.title = title || undefined;
    this.availableMetricTypes = availableMetricTypes;
    this.commissioningState = commissioningState;
    this.maintenanceModeCommand = availableCommands.find(
      (command) => command.payload.case === 'maintenanceMode',
    );
    this.mode = mode;
    this.peripherals = peripherals.map(
      (peripheral) => new PeripheralModel(peripheral),
    );
    this.isLinked =
      commissioningState === Device_CommissioningState.OPERATIONAL ||
      commissioningState === Device_CommissioningState.READY_FOR_TESTING ||
      commissioningState === Device_CommissioningState.PENDING_CLOUD_CONNECTION;

    this.allowMaintenanceMode =
      !!this.maintenanceModeCommand &&
      commissioningState === Device_CommissioningState.OPERATIONAL;

    this.updatingFirmwareOTA = otaState === Device_OTAState.OTA_STATE_UPDATING;

    this.allowedProductTypesLinked = new Map(
      allowedProductTypesLinked.map((value) => [value, true]),
    );
  }

  static createEmpty(): DeviceModel {
    return new DeviceModel(
      new Device({
        name: 'empty',
        title: t('systemDesign.product.edit.fields.labels.notConnected'),
        commissioningState: Device_CommissioningState.READY_FOR_LINKING,
      }),
    );
  }
}
