import { PlainMessage } from '@bufbuild/protobuf';
import {
  Reservoir,
  Reservoir_BlueRoof,
  Reservoir_CrateType,
  Reservoir_DryProtection,
  Reservoir_GreenRoof,
  Reservoir_GreenType,
  Reservoir_InfiltrationTank,
  Reservoir_Pond,
  Reservoir_Tank,
  Reservoir_Wadi,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { TFunction } from 'i18next';
import { IssueModel } from '~/shared/models/issues/IssueModel';
import { roundToNDecimals } from '~/shared/models/metrics/metric-conversions';
import { WaterLevelsModel } from '~/shared/models/metrics/waterLevelsModel/WaterLevelsModel';
import { ProductModel } from '~/shared/models/system/ProductModel';
import { ReservoirFormValues } from '~/shared/models/system/ReservoirFormTypes';
import {
  asFloat,
  asInt,
  assertIsDefined,
  assertUnreachable,
} from '~/types/assert-type';

type BlueRoofProperties = PlainMessage<Reservoir_BlueRoof>;

type GreenRoofProperties = PlainMessage<Reservoir_GreenRoof>;

type TankProperties = PlainMessage<Reservoir_Tank>;

type InfiltrationTankProperties = PlainMessage<Reservoir_InfiltrationTank>;

type PondProperties = PlainMessage<Reservoir_Pond>;

type WadiProperties = PlainMessage<Reservoir_Wadi>;

export type ReservoirParameterDetails = {
  title: string;
  value: string | number;
  unit?: string;
  redirect?: boolean;
  big?: boolean;
  tooltip?: string;
};

type TypeProperties = NonNullable<Reservoir['typeProperties']>;

export type ReservoirType = Exclude<
  TypeProperties extends { case: infer T } ? T : never,
  'sewer' | undefined
>;

export const reservoirCrateTypes = Object.values(Reservoir_CrateType).filter(
  (value) => typeof value === 'number',
);

type ReservoirParameter =
  | keyof Pick<
      ReservoirFormValues,
      | 'area'
      | 'areaGreen'
      | 'captureArea'
      | 'factorHollow'
      | 'greenType'
      | 'drainageSpeed'
      | 'dryProtection'
      | 'externalUsage'
      | 'maxWaterHeight'
      | 'minWaterHeight'
      | 'pondNapOffset'
      | 'targetWaterHeight'
      | 'targetWaterHeightGrowingMonths'
      | 'type'
      | 'wadiMaxVolume'
    >
  | 'currentWaterLevel'
  | 'reservoirCapacityUsed';

type ReservoirPropertiesMap = {
  blueRoof: BlueRoofProperties;
  greenRoof: GreenRoofProperties;
  tank: TankProperties;
  infiltrationTank: InfiltrationTankProperties;
  pond: PondProperties;
  wadi: WadiProperties;
};

type ReservoirProperties<T extends ReservoirType> = {
  type: T;
} & ReservoirPropertiesMap[T];

type ReservoirCaseProperties<T extends ReservoirType> = {
  case: T;
  value: ReservoirPropertiesMap[T];
};

type ReservoirFormTypeProperties = {
  [K in ReservoirType]: ReservoirProperties<K>;
}[ReservoirType];

type ReservoirApiTypeProperties = {
  [K in ReservoirType]: ReservoirCaseProperties<K>;
}[ReservoirType];

export class ReservoirModel {
  readonly title: string;

  readonly id: string;

  readonly name: string;

  readonly maxWaterHeightMm: number;

  private minWaterHeightMm: number;

  private targetWaterHeightMm: number;

  private targetWaterHeightGrowingMonthsMm: number;

  private factorHollow: number;

  readonly products: ProductModel[];

  readonly sideLengthBlue: number;

  readonly areaBlueM2: number = 0;

  readonly type: ReservoirType;

  private typeProperties: ReservoirFormTypeProperties;

  readonly uiPosition: { x: number; y: number };

  readonly issues?: IssueModel[];

  constructor({
    reservoir,
    products,
    issues,
  }: {
    reservoir: Reservoir;
    products: ProductModel[];
    issues?: IssueModel[];
  }) {
    this.id = ReservoirModel.extractReservoirId(reservoir.name);
    this.title = reservoir.title;
    this.name = reservoir.name;
    this.maxWaterHeightMm = Number(reservoir.maxWaterHeightMm);

    this.minWaterHeightMm = Number(reservoir.minWaterHeightMm);

    this.targetWaterHeightMm = reservoir.targetWaterHeight;

    this.targetWaterHeightGrowingMonthsMm =
      reservoir.targetWaterHeightGrowingMonths;

    this.factorHollow = Math.round(reservoir.factorHollow * 100);

    this.sideLengthBlue = Math.sqrt(Number(reservoir.areaM2));

    this.areaBlueM2 = Number(reservoir.areaM2);

    this.typeProperties = ReservoirModel.createTypeProperties(
      reservoir.typeProperties,
    );

    this.type = this.typeProperties.type;

    assertIsDefined(reservoir.uiPosition);

    this.uiPosition = reservoir.uiPosition;

    this.products = products;

    this.issues = issues;
  }

  get areaGreenM2() {
    return this.typeProperties.type === 'greenRoof'
      ? this.typeProperties.areaGreen
      : 0;
  }

  static extractReservoirId(name: Reservoir['name']): string {
    const regex = /\/reservoirs\/([^/]+)/;
    const match = name.match(regex);

    assertIsDefined(match);
    return match[1];
  }

  private static createTypeProperties(
    typeProperties: Reservoir['typeProperties'],
  ): ReservoirFormTypeProperties {
    const typeCase = typeProperties.case;
    switch (typeCase) {
      case 'blueRoof':
        return {
          type: typeCase,
          crateType: typeProperties.value.crateType,
        };
      case 'greenRoof':
        return {
          type: typeCase,
          areaGreen: typeProperties.value.areaGreen,
          greenType: typeProperties.value.greenType,
          crateType: typeProperties.value.crateType,
        };
      case 'tank':
        return {
          type: typeCase,
          captureArea: typeProperties.value.captureArea,
          dryProtection: typeProperties.value.dryProtection,
          externalUsage: typeProperties.value.externalUsage,
        };
      case 'infiltrationTank':
        return {
          type: typeCase,
          drainageSpeed: roundToNDecimals(
            typeProperties.value.drainageSpeed,
            2,
          ),
        };
      case 'pond':
        return {
          type: typeCase,
          napOffset: typeProperties.value.napOffset,
        };
      case 'wadi':
        return {
          type: typeCase,
          maxVolume: typeProperties.value.maxVolume,
          drainageSpeed: roundToNDecimals(
            typeProperties.value.drainageSpeed,
            2,
          ),
        };
      case 'sewer':
        throw new Error('Reservoir type sewer not expected');
      case undefined:
        throw new Error('Expected a reservoir type, but got undefined');
      default:
        return assertUnreachable(typeCase);
    }
  }

  private static formValuesToApiTypeProperties(
    formValues: ReservoirFormValues,
  ): ReservoirApiTypeProperties {
    switch (formValues.type) {
      case 'blueRoof':
        return {
          case: 'blueRoof',
          value: {
            crateType: asInt(formValues.crateType),
          },
        };
      case 'greenRoof':
        return {
          case: 'greenRoof',
          value: {
            areaGreen: asFloat(formValues.areaGreen),
            greenType: asInt(formValues.greenType),
            crateType: asInt(formValues.crateType),
          },
        };
      case 'tank':
        return {
          case: 'tank',
          value: {
            dryProtection: asInt(formValues.dryProtection),
            externalUsage: asInt(formValues.externalUsage),
            captureArea: asFloat(formValues.captureArea),
          },
        };
      case 'infiltrationTank':
        return {
          case: 'infiltrationTank',
          value: {
            drainageSpeed: asFloat(formValues.drainageSpeed),
          },
        };
      case 'pond':
        return {
          case: 'pond',
          value: {
            napOffset: asFloat(formValues.pondNapOffset),
          },
        };
      case 'wadi':
        return {
          case: 'wadi',
          value: {
            maxVolume: asFloat(formValues.wadiMaxVolume),
            drainageSpeed: asFloat(formValues.drainageSpeed),
          },
        };
      default:
        return assertUnreachable(formValues.type);
    }
  }

  asFormValues(): ReservoirFormValues {
    return {
      area: this.areaBlueM2.toString(),
      type: this.typeProperties.type,
      areaGreen:
        this.typeProperties.type === 'greenRoof'
          ? this.typeProperties.areaGreen.toString()
          : '',
      dryProtection:
        this.typeProperties.type === 'tank'
          ? this.typeProperties.dryProtection.toString()
          : '',
      externalUsage:
        this.typeProperties.type === 'tank'
          ? this.typeProperties.externalUsage.toString()
          : '',
      captureArea:
        this.typeProperties.type === 'tank'
          ? this.typeProperties.captureArea.toString()
          : '',
      factorHollow: this.factorHollow.toString(),
      greenType:
        this.typeProperties.type === 'greenRoof'
          ? this.typeProperties.greenType.toString()
          : '',
      drainageSpeed:
        this.typeProperties.type === 'infiltrationTank' ||
        this.typeProperties.type === 'wadi'
          ? this.typeProperties.drainageSpeed.toString()
          : '',
      maxWaterHeight: this.maxWaterHeightMm.toString(),
      minWaterHeight: this.minWaterHeightMm.toString(),
      pondNapOffset:
        this.typeProperties.type === 'pond'
          ? this.typeProperties.napOffset.toString()
          : '',
      targetWaterHeight: this.targetWaterHeightMm.toString(),
      targetWaterHeightGrowingMonths:
        this.targetWaterHeightGrowingMonthsMm.toString(),
      title: this.title,
      wadiMaxVolume:
        this.typeProperties.type === 'wadi'
          ? this.typeProperties.maxVolume.toString()
          : '',
      crateType:
        this.typeProperties.type === 'blueRoof' ||
        this.typeProperties.type === 'greenRoof'
          ? this.typeProperties.crateType.toString()
          : '',
    };
  }

  toApiReservoir(formValues: ReservoirFormValues): Reservoir {
    return new Reservoir({
      name: this.name,
      uiPosition: this.uiPosition,
      title: formValues.title,
      areaM2: BigInt(formValues.area),
      maxWaterHeightMm: BigInt(formValues.maxWaterHeight),
      minWaterHeightMm: BigInt(formValues.minWaterHeight),
      targetWaterHeight: asFloat(formValues.targetWaterHeight),
      targetWaterHeightGrowingMonths: asFloat(
        formValues.targetWaterHeightGrowingMonths,
      ),
      factorHollow: asInt(formValues.factorHollow) / 100,
      typeProperties: ReservoirModel.formValuesToApiTypeProperties(formValues),
    });
  }

  static getLabelForType(type: ReservoirType, t: TFunction) {
    switch (type) {
      case 'blueRoof':
        return t('reservoirEdit.fields.type.blueRoof');
      case 'greenRoof':
        return t('reservoirEdit.fields.type.greenRoof');
      case 'tank':
        return t('reservoirEdit.fields.type.tank');
      case 'infiltrationTank':
        return t('reservoirEdit.fields.type.infiltrationTank');
      case 'pond':
        return t('reservoirEdit.fields.type.pond');
      case 'wadi':
        return t('reservoirEdit.fields.type.wadi');
      default:
        return assertUnreachable(type);
    }
  }

  static getLabelForGreenType(greenType: Reservoir_GreenType, t: TFunction) {
    switch (greenType) {
      case Reservoir_GreenType.DROUGHT_PROOF:
        return t('reservoirEdit.fields.greenType.droughtProof');
      case Reservoir_GreenType.SEDUM:
        return t('reservoirEdit.fields.greenType.sedum');
      case Reservoir_GreenType.BIODIVERSE_SEDUM:
        return t('reservoirEdit.fields.greenType.biodiverseSedum');
      case Reservoir_GreenType.SEED_MIX:
        return t('reservoirEdit.fields.greenType.seedMix');
      case Reservoir_GreenType.GRASSES:
        return t('reservoirEdit.fields.greenType.grasses');
      case Reservoir_GreenType.FULL_GARDEN:
        return t('reservoirEdit.fields.greenType.fullGarden');
      case Reservoir_GreenType.UNSPECIFIED:
        throw new Error('Unsupported green type');
      default:
        return assertUnreachable(greenType);
    }
  }

  static getLabelForDryProtection(
    dryProtection: Reservoir_DryProtection,
    t: TFunction,
  ) {
    switch (dryProtection) {
      case Reservoir_DryProtection.DISABLED:
        return t('reservoirEdit.fields.dryProtection.disabled');
      case Reservoir_DryProtection.GROWING:
        return t('reservoirEdit.fields.dryProtection.growing');
      case Reservoir_DryProtection.OUTSIDE_GROWING:
        return t('reservoirEdit.fields.dryProtection.outsideGrowing');
      case Reservoir_DryProtection.ALWAYS:
        return t('reservoirEdit.fields.dryProtection.always');
      case Reservoir_DryProtection.UNSPECIFIED:
        throw new Error('Unsupported dry protection');
      default:
        return assertUnreachable(dryProtection);
    }
  }

  static getLabelForCrateType(crateType: Reservoir_CrateType, t: TFunction) {
    switch (crateType) {
      case Reservoir_CrateType.WAVIN_AQUACELL_UP_160:
        return t('reservoirEdit.fields.crateType.wavinAquacellUp160');
      case Reservoir_CrateType.WAVIN_AQUACELL_UP_160_CAPILLARITY:
        return t(
          'reservoirEdit.fields.crateType.wavinAquacellUp160Capillarity',
        );
      case Reservoir_CrateType.WAVIN_AQUACELL_UP_85:
        return t('reservoirEdit.fields.crateType.wavinAquacellUp85');
      case Reservoir_CrateType.WAVIN_AQUACELL_UP_85_CAPILLARITY:
        return t('reservoirEdit.fields.crateType.wavinAquacellUp85Capillarity');
      case Reservoir_CrateType.OTHER:
        return t('reservoirEdit.fields.crateType.other');
      case Reservoir_CrateType.UNSPECIFIED:
        return t('reservoirEdit.fields.crateType.unspecified');
      default:
        return assertUnreachable(crateType);
    }
  }

  static getUnitForParameter(parameter: ReservoirParameter, t: TFunction) {
    switch (parameter) {
      case 'currentWaterLevel':
      case 'minWaterHeight':
      case 'maxWaterHeight':
      case 'targetWaterHeight':
      case 'targetWaterHeightGrowingMonths':
        return 'mm';
      case 'area':
      case 'areaGreen':
      case 'captureArea':
        return 'm²';
      case 'reservoirCapacityUsed':
      case 'factorHollow':
        return '%';
      case 'drainageSpeed':
        return t('reservoirEdit.fields.units.drainageSpeed');
      case 'externalUsage':
        return t('reservoirEdit.fields.units.externalUsage');
      case 'wadiMaxVolume':
        return 'l';
      case 'pondNapOffset':
        return 'm';
      default:
        return '';
    }
  }

  createReservoirParameterDetails(
    t: TFunction,
    waterLevels: WaterLevelsModel,
    showAdditionalData?: boolean,
    showPredictions?: boolean,
  ): ReservoirParameterDetails[] {
    const currentWaterLevel =
      waterLevels.getWaterLevelByReservoirName(this.name) || 0;

    const parameterDetails: ReservoirParameterDetails[] = [
      {
        title: t('reservoirEdit.fields.labels.currentWaterLevel'),
        value: Math.max(0, Math.round(currentWaterLevel)),
        unit: ReservoirModel.getUnitForParameter('currentWaterLevel', t),
        redirect: !!showPredictions,
        big: true,
      },
      {
        title: t('reservoirEdit.fields.labels.reservoirCapacityUsed'),
        value: Math.max(
          0,
          Math.round((100 * currentWaterLevel) / this.maxWaterHeightMm),
        ),
        unit: ReservoirModel.getUnitForParameter('reservoirCapacityUsed', t),
        redirect: !!showPredictions,
        big: true,
      },
      {
        title: t('reservoirEdit.fields.labels.type'),
        value: ReservoirModel.getLabelForType(this.type, t),
      },
      {
        title: t('reservoirEdit.fields.labels.area'),
        value: this.areaBlueM2,
        unit: ReservoirModel.getUnitForParameter('area', t),
        tooltip: t('reservoirEdit.fields.tooltips.area'),
      },
    ];

    if (this.typeProperties.type === 'greenRoof') {
      parameterDetails.push(
        {
          title: t('reservoirEdit.fields.labels.greenType'),
          value: ReservoirModel.getLabelForGreenType(
            this.typeProperties.greenType,
            t,
          ),
        },
        {
          title: t('reservoirEdit.fields.labels.areaGreen'),
          value: this.typeProperties.areaGreen,
          unit: ReservoirModel.getUnitForParameter('areaGreen', t),
          tooltip: t('reservoirEdit.fields.tooltips.areaGreen'),
        },
      );
    }
    if (!showAdditionalData) return parameterDetails;

    if (
      this.typeProperties.type === 'greenRoof' ||
      this.typeProperties.type === 'blueRoof'
    ) {
      parameterDetails.push({
        title: t('reservoirEdit.fields.labels.crateType'),
        value: ReservoirModel.getLabelForCrateType(
          this.typeProperties.crateType,
          t,
        ),
      });
    }

    parameterDetails.push(
      {
        title: t('reservoirEdit.fields.labels.minWaterHeight'),
        value: this.minWaterHeightMm,
        unit: ReservoirModel.getUnitForParameter('minWaterHeight', t),
        tooltip: t('reservoirEdit.fields.tooltips.minWaterHeight'),
      },
      {
        title: t('reservoirEdit.fields.labels.maxWaterHeight'),
        value: this.maxWaterHeightMm,
        unit: ReservoirModel.getUnitForParameter('maxWaterHeight', t),
        tooltip: t('reservoirEdit.fields.tooltips.maxWaterHeight'),
      },
      {
        title: t('reservoirEdit.fields.labels.targetWaterHeight'),
        value: this.targetWaterHeightMm,
        unit: ReservoirModel.getUnitForParameter('targetWaterHeight', t),
        tooltip: t('reservoirEdit.fields.tooltips.targetWaterHeight'),
      },
      {
        title: t('reservoirEdit.fields.labels.targetWaterHeightGrowingMonths'),
        value: this.targetWaterHeightGrowingMonthsMm,
        unit: ReservoirModel.getUnitForParameter(
          'targetWaterHeightGrowingMonths',
          t,
        ),
        tooltip: t(
          'reservoirEdit.fields.tooltips.targetWaterHeightGrowingMonths',
        ),
      },
      {
        title: t('reservoirEdit.fields.labels.factorHollow'),
        value: this.factorHollow,
        unit: ReservoirModel.getUnitForParameter('factorHollow', t),
        tooltip: t('reservoirEdit.fields.tooltips.factorHollow'),
      },
    );

    if (this.typeProperties.type === 'tank') {
      parameterDetails.push(
        {
          title: t('reservoirEdit.fields.labels.dryProtection'),
          value: ReservoirModel.getLabelForDryProtection(
            this.typeProperties.dryProtection,
            t,
          ),
          tooltip: t('reservoirEdit.fields.tooltips.dryProtection'),
        },
        {
          title: t('reservoirEdit.fields.labels.externalUsage'),
          value: this.typeProperties.externalUsage,
          unit: ReservoirModel.getUnitForParameter('externalUsage', t),
          tooltip: t('reservoirEdit.fields.tooltips.externalUsage'),
        },
        {
          title: t('reservoirEdit.fields.labels.captureArea'),
          value: this.typeProperties.captureArea,
          unit: ReservoirModel.getUnitForParameter('captureArea', t),
          tooltip: t('reservoirEdit.fields.tooltips.captureArea'),
        },
      );
    }

    if (this.typeProperties.type === 'infiltrationTank') {
      parameterDetails.push({
        title: t('reservoirEdit.fields.labels.drainageSpeed'),
        value: this.typeProperties.drainageSpeed,
        unit: ReservoirModel.getUnitForParameter('drainageSpeed', t),
        tooltip: t('reservoirEdit.fields.tooltips.drainageSpeed'),
      });
    }

    if (this.typeProperties.type === 'pond') {
      parameterDetails.push({
        title: t('reservoirEdit.fields.labels.pondNapOffset'),
        value: this.typeProperties.napOffset,
        unit: ReservoirModel.getUnitForParameter('pondNapOffset', t),
      });
    }

    if (this.typeProperties.type === 'wadi') {
      parameterDetails.push(
        {
          title: t('reservoirEdit.fields.labels.wadiMaxVolume'),
          value: this.typeProperties.maxVolume,
          unit: ReservoirModel.getUnitForParameter('wadiMaxVolume', t),
          tooltip: t('reservoirEdit.fields.tooltips.wadiMaxVolume'),
        },
        {
          title: t('reservoirEdit.fields.labels.drainageSpeed'),
          value: this.typeProperties.drainageSpeed,
          unit: ReservoirModel.getUnitForParameter('drainageSpeed', t),
          tooltip: t('reservoirEdit.fields.tooltips.drainageSpeed'),
        },
      );
    }

    return parameterDetails;
  }
}
