import { ListMetricsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { MetricModel } from '~/shared/models/metrics/MetricModel';

export class MetricsModel {
  readonly metrics: MetricModel[];

  private metricByType = new Map<MetricType, MetricModel>();

  readonly oldestTimestamp: Date | undefined;

  readonly latestValveHeight: number | undefined;

  constructor(response?: ListMetricsResponse) {
    this.metrics = response
      ? response.metrics.map((metric) => new MetricModel(metric))
      : [];

    this.metrics = this.sortMetrics();

    this.metrics.forEach((metric) => {
      this.metricByType.set(metric.type, metric);
    });

    const validTimestamps = this.metrics
      .map((metric) => metric.lastDataPoint && metric.lastDataPoint[0])
      .filter(Boolean) as number[];

    if (validTimestamps.length === 0) {
      this.oldestTimestamp = undefined;
    } else {
      const oldestTimestampValue = Math.min(...validTimestamps);
      this.oldestTimestamp = new Date(oldestTimestampValue);
    }

    this.latestValveHeight = this.metrics
      .find((metric) => metric.type === MetricType.VALVE_HEIGHT_MM)
      ?.getLastNumberValue();
  }

  private sortMetrics(): MetricModel[] {
    const sortOrderMap = new Map<number, number>();

    MetricsModel.METRIC_VIEW_ORDER.forEach((_, i) => {
      sortOrderMap.set(MetricsModel.METRIC_VIEW_ORDER[i], i);
    });

    return [...this.metrics].sort((a, b) => {
      const orderA = sortOrderMap.get(a.type);
      const orderB = sortOrderMap.get(b.type);

      // If a.type or b.type is not part of the METRIC_VIEW_ORDER, it should come after all other metrics that are in sortOrderMap
      if (orderA === undefined) {
        return 1;
      }

      if (orderB === undefined) {
        return -1;
      }

      return orderA - orderB;
    });
  }

  findMetricByType(type: MetricType): MetricModel | undefined {
    return this.metricByType.get(type);
  }

  static readonly METRIC_VIEW_ORDER = [
    MetricType.WATER_HEIGHT_MM,
    MetricType.VALVE_HEIGHT_MM,
    MetricType.AIR_TEMPERATURE_CELSIUS,
    MetricType.WATER_TEMPERATURE_CELSIUS,
    MetricType.PRECIPITATION_MM,
    MetricType.TAP_ON,
    MetricType.BATTERY_PERCENTAGE,
    MetricType.WIND_SPEED_METER_PER_SECOND,
    MetricType.WIND_GUST_SPEED_METER_PER_SECOND,
    MetricType.WIND_DIRECTION_DEGREES,
    MetricType.WIND_GUST_DIRECTION_DEGREES,
    MetricType.CELLULAR_RSSI_DBM,
    MetricType.FLOW_RATE_L_SECOND,
    MetricType.PUMP_STATE_ON,
    MetricType.HUMIDITY_PERCENTAGE,
    MetricType.FLOW_DIFFERENCE_L,
    MetricType.VALVE_STATE_ON,
  ];
}
