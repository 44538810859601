import { ListIssuesResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import {
  Device,
  Device_CommissioningState,
  Drain,
  Peripheral,
  Product,
  SilentHours,
  System,
  System_OperationMode,
  SystemAutomation,
  Topology,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { idFromName } from '~/shared/models/id-utils';
import { IssueModel } from '~/shared/models/issues/IssueModel';
import { IssuesModel } from '~/shared/models/issues/IssuesModel';
import { DeviceModel } from '~/shared/models/system/DeviceModel';
import { DrainModel } from '~/shared/models/system/DrainModel';
import { ReservoirModel } from '~/shared/models/system/ReservoirModel';
import { ProductInfo, ProductModel } from '~/shared/models/system/ProductModel';
import {
  productionStateToSystemState,
  SystemState,
} from '~/shared/models/system/SystemState';
import {
  assertIsDefined,
  assertIsNonBlankString,
  assertUnreachable,
} from '~/types/assert-type';

export { ReservoirModel };

export type Automation = 'on' | 'off';

function getAutomationStatus(automation: SystemAutomation): 'off' | 'on' {
  switch (automation) {
    case SystemAutomation.ON:
      return 'on';
    case SystemAutomation.OFF:
      return 'off';
    case SystemAutomation.UNSPECIFIED:
      throw new Error('Detected invalid automation UNSPECIFIED');
    default:
      return assertUnreachable(automation);
  }
}

export class SystemModel {
  readonly id: string;

  readonly title: string;

  readonly name: string;

  readonly automation: Automation;

  readonly reservoirs: ReservoirModel[];

  readonly drains: DrainModel[];

  readonly pressurePipes: DrainModel[];

  readonly products: ProductModel[];

  readonly devices: DeviceModel[];

  readonly state: SystemState;

  readonly googlePlaceId?: string;

  readonly location: string;

  readonly crmNumber?: string;

  readonly serviceContractEndDate?: Date;

  readonly remarks?: string;

  readonly minTemperature: number;

  readonly growingMonths: number[];

  readonly topologyName: string;

  readonly silentHours?: SilentHours;

  readonly operationMode: System_OperationMode;

  private productByIdMap = new Map<string, ProductModel>();

  private reservoirByIdMap = new Map<string, ReservoirModel>();

  private drainByIdMap = new Map<string, DrainModel>();

  private pressurePipeByIdMap = new Map<string, DrainModel>();

  private productByDrainName = new Map<string, Product>();

  private productsByDeviceNameMap = new Map<string, ProductModel[]>();

  private deviceByNameMap = new Map<string, DeviceModel>();

  private peripheralByName = new Map<string, Peripheral>();

  private issuesByResourceName = new Map<string, IssueModel[]>();

  private productRelations = new Map<ProductModel, ProductModel[]>();

  constructor(
    system: System,
    issuesResponse: ListIssuesResponse = new ListIssuesResponse(),
  ) {
    assertIsNonBlankString(system.name);
    assertIsNonBlankString(system.title);

    this.id = idFromName(system.name);
    this.title = system.title;
    this.name = system.name;
    this.googlePlaceId = system.googlePlaceId || undefined;
    this.location = system.location;
    this.crmNumber = system.crmNumber || undefined;
    this.remarks = system.remarks || undefined;
    this.minTemperature = system.minTemperature;
    this.growingMonths = system.growingMonths;
    this.serviceContractEndDate = system.serviceContractEndDate?.toDate();
    this.automation = getAutomationStatus(system.automation);
    this.operationMode = system.operationMode;
    this.state = productionStateToSystemState(system.productionState);
    this.silentHours = system.silentHours;

    const issuesModel = new IssuesModel(issuesResponse);
    issuesModel.issues.forEach((issue) => {
      if (!this.issuesByResourceName.has(issue.resourceName)) {
        this.issuesByResourceName.set(issue.resourceName, []);
      }
      this.issuesByResourceName.get(issue.resourceName)?.push(issue);
    });

    this.initPeripheralMaps(system.devices);
    const topology = system.topologies.at(0);

    assertIsDefined(topology);

    this.topologyName = topology.name;
    this.devices = this.initDevices(system.devices);

    this.products = this.initProducts(topology);
    this.reservoirs = this.initReservoirs(topology);
    this.initReservoirByIdMap();
    this.initProductByIdMap();
    this.initProductByDrainName(topology.products);
    this.drains = this.initDrains(topology.drains);
    this.pressurePipes = this.initPressurePipes(topology.drains);

    this.devices = system.devices.map((device) => new DeviceModel(device));

    this.initDrainByIdMap();
    this.initPressurePipeByIdMap();
    this.initProductByDeviceName();
    this.initProductRelations();
  }

  private initDevices(devices: Device[]) {
    const deviceModels = devices.map((device) => new DeviceModel(device));
    deviceModels.forEach((device) => {
      this.deviceByNameMap.set(device.name, device);
    });
    return deviceModels;
  }

  private initProductByDrainName(products: Product[]) {
    products.forEach((product) => {
      const { position } = product;
      if (!position) {
        return;
      }
      this.productByDrainName.set(position.drainName, product);
    });
  }

  private initDrains(drains: Drain[]): DrainModel[] {
    return drains
      .filter((drain) => !drain.pressurePipe)
      .map((drain) => {
        const product = this.productByDrainName.get(drain.name);

        return new DrainModel(drain, product);
      });
  }

  private initPressurePipes(drains: Drain[]): DrainModel[] {
    return drains
      .filter((drain) => drain.pressurePipe)
      .map((drain) => {
        const product = this.productByDrainName.get(drain.name);

        return new DrainModel(drain, product);
      });
  }

  productById(productId: string) {
    return this.productByIdMap.get(productId);
  }

  drainById(drainId: string) {
    return this.drainByIdMap.get(drainId);
  }

  pressurePipeById(drainId: string) {
    return this.pressurePipeByIdMap.get(drainId);
  }

  reservoirById(reservoirId: string) {
    return this.reservoirByIdMap.get(reservoirId);
  }

  getReservoirNames() {
    return this.reservoirs.map((reservoir) => reservoir.name);
  }

  productsByDevice(device: DeviceModel) {
    return this.productsByDeviceNameMap.get(device.name) ?? [];
  }

  getParentProduct(product: ProductModel) {
    return Array.from(this.productRelations.entries()).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_parentProduct, children]) => children.find((p) => p.id === product.id),
    )?.[0];
  }

  get hasUncommissionedDevices() {
    return this.devices.some(
      (device) =>
        device.commissioningState !== Device_CommissioningState.OPERATIONAL,
    );
  }

  get hasDevicesWithPendingCloudConnection() {
    return this.devices.some(
      (device) =>
        device.commissioningState ===
        Device_CommissioningState.PENDING_CLOUD_CONNECTION,
    );
  }

  get hasDevicesWithPendingOTAFirmwareUpdate() {
    return this.devices.some((device) => device.updatingFirmwareOTA);
  }

  private initPeripheralMaps(devices: Device[]) {
    devices.forEach((device) => {
      device.peripherals.forEach((peripheral) => {
        this.peripheralByName.set(peripheral.name, peripheral);
      });
    });
  }

  private getPeripheralsForProduct(product: Product): Peripheral[] {
    return product.peripheralResourceNames.map((resourceName) =>
      this.getPeripheralByName(resourceName),
    );
  }

  private getPeripheralByName(resourceName: string): Peripheral {
    const peripheral = this.peripheralByName.get(resourceName);
    assertIsDefined(peripheral);
    return peripheral;
  }

  private initProducts(topology: Topology): ProductModel[] {
    return (topology?.products ?? []).map((product) => {
      const productReservoir = topology.reservoirs.find(
        (reservoir) => reservoir.name === product.position?.reservoirName,
      );

      return new ProductModel({
        ...this.createProductInfo(product),
        nextProductName: SystemModel.getNextProductName(
          product.name,
          topology.products,
        ),
        previousProductName: SystemModel.getPreviousProductName(
          product.name,
          topology.products,
        ),
        reservoir: productReservoir,
      });
    });
  }

  private initReservoirs(topology: Topology): ReservoirModel[] {
    return (topology?.reservoirs ?? [])
      .sort((a, b) => {
        if (a.title === b.title) {
          return a.name.localeCompare(b.name);
        }

        return a.title.localeCompare(b.title);
      })
      .map((reservoir) => {
        const reservoirProducts = this.products.filter(
          (product) => product.reservoirName === reservoir.name,
        );

        return new ReservoirModel({
          reservoir,
          products: reservoirProducts,
          issues: this.issuesByResourceName.get(reservoir.name),
        });
      });
  }

  private createProductInfo(product: Product): ProductInfo {
    const peripherals = this.getPeripheralsForProduct(product);

    const device =
      this.deviceByNameMap.get(product.mainDeviceResourceName) ||
      DeviceModel.createEmpty();
    return {
      product,
      device,
      peripherals,
      issues: this.issuesByResourceName.get(device.name) ?? [],
    };
  }

  private initDrainByIdMap() {
    this.drains.forEach((drain) => {
      this.drainByIdMap.set(drain.id, drain);
    });
  }

  private initPressurePipeByIdMap() {
    this.pressurePipes.forEach((drain) => {
      this.pressurePipeByIdMap.set(drain.id, drain);
    });
  }

  private initReservoirByIdMap() {
    this.reservoirs.forEach((reservoir) => {
      this.reservoirByIdMap.set(reservoir.id, reservoir);
    });
  }

  private initProductByIdMap() {
    this.products.forEach((product) => {
      this.productByIdMap.set(product.id, product);
    });
  }

  private initProductByDeviceName() {
    this.products.forEach((product) => {
      if (!this.productsByDeviceNameMap.has(product.device.name)) {
        this.productsByDeviceNameMap.set(product.device.name, [product]);
      } else {
        this.productsByDeviceNameMap.get(product.device.name)?.push(product);
      }
    });
  }

  private initProductRelations() {
    this.products.forEach((product) => {
      if (product.containsDevice) {
        const childProducts = this.productsByDevice(product.device);

        this.productRelations.set(
          product,
          childProducts.filter((child) => !child.containsDevice),
        );
      }
    });
  }

  private static getNextProductName(
    productName: string,
    products: Product[],
  ): string {
    const currentProductIndex = products.findIndex(
      (product) => product.name === productName,
    );

    const nextProductIndex = (currentProductIndex + 1) % products.length;
    const nextProduct = products[nextProductIndex];

    return nextProduct.name;
  }

  private static getPreviousProductName(
    productName: string,
    products: Product[],
  ): string {
    const currentProductIndex = products.findIndex(
      (product) => product.name === productName,
    );

    const previousProductIndex =
      (currentProductIndex - 1 + products.length) % products.length;
    const previousProduct = products[previousProductIndex];

    return previousProduct.name;
  }
}
